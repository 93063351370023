import React, { Component } from 'react';
import Categories from '../Categories/Categories'
import FaqList from '../FaqList/FaqList'
import { HelpCenterData } from '../../context/HelpcenterDataContext';
import { withRouter } from 'react-router-dom';
import {CommonUtils} from '../../utils/CommonUtils';
import {HelpcenterClient} from '../../utils/HelpcenterClient';




class Homepage extends Component {
    static contextType = HelpCenterData;
    constructor(props){
        super(props);
        this.state = {
            faqCategoryList: [],
            categoriesLoaded: false,
            
        };
    };

    getAllCategories = () => {
        let params = {
            applicationId : this.context.helpCenter.appId,
            status: 'published'
          }
        HelpcenterClient.getAllFaqCategories(params).then(response => {
            if (response.status === 200 && response.data && response.data.code === "SUCCESS") {
                let categoryList = response.data.data || [];
                this.setState({
                    faqCategoryList: categoryList,
                    categoriesLoaded: true,
                })
            }
        }).catch(err => {
            console.log(err)
        })
    }

    componentDidMount = () => {
        this.getAllCategories();
    }

    render() {
        return this.state.faqCategoryList && (CommonUtils.getUrlParameter(window.location.search,"q") || this.state.faqCategoryList.length === 1 )? <FaqList/>: <Categories list={this.state.faqCategoryList}/>;
    }
}

Homepage.contextType = HelpCenterData;
export default Object.assign(withRouter(Homepage), { contextType: undefined });