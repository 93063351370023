export const DEFAULT_HELPCENTER_SETTINGS = {
  color: "#5553b7",
  heading: "Hi, how can we help you?",
  logo: "https://s3.amazonaws.com/kommunicate-prod.s3/logo/kommunicate.png",
  title: "Helpcenter",
  showPoweredBy: true,
  searchBarText: "Search Helpcenter",
  showChatWidget:false
};
export const PRODUCT_ID = {
  kommunicate: 1,
  helpcenter: 3
};
export const KM_PLAN_ID = {
  TRIAL: "trial",
  STARTUP: "startup",

  PER_AGENT_YEARLY: "per_agent_yearly",
  PER_AGENT_MONTHLY: "per_agent_monthly",

  PER_AGENT_YEARLY_V3: "per_agent_yearly_v3",
  PER_AGENT_MONTHLY_V3: "per_agent_monthly_v3",
  PER_AGENT_YEARLY_V3_INR: "per_agent_yearly_v3_inr",
  PER_AGENT_MONTHLY_V3_INR: "per_agent_monthly_v3_inr",

  ENTERPRISE_YEARLY: "enterprise",
  ENTERPRISE_MONTHLY: "enterprise-per-agent-monthly",
  ENTERPRISE_YEARLY_V3: "enterprise_v3",
  ENTERPRISE_MONTHLY_V3: "enterprise-per-agent-monthly-v3",
  ENTERPRISE_YEARLY_V3_INR: "enterprise_v3_inr",
  ENTERPRISE_MONTHLY_V3_INR: "enterprise-per-agent-monthly-v3-inr",

  PRO_MONTHLY: "pro_monthly",
  PRO_YEARLY: "pro_yearly",
  PRO_MONTHLY_V3: "pro_monthly_v3",
  PRO_YEARLY_V3: "pro_yearly_v3",
  PRO_MONTHLY_V3_INR: "pro_monthly_v3_inr",
  PRO_YEARLY_V3_INR: "pro_yearly_v3_inr",

  SCALE_MONTHLY_V1: "scale_monthly_v1",
  SCALE_YEARLY_V1: "scale_yearly_v1",
  SCALE_MONTHLY_V1_INR: "scale_monthly_v1_inr",
  SCALE_YEARLY_V1_INR: "scale_yearly_v1_inr",
  BUSINESS_MONTHLY_V1: "business_monthly_v1",
  BUSINESS_YEARLY_V1: "business_yearly_v1",
  BUSINESS_MONTHLY_V1_INR: "business_monthly_v1_inr",
  BUSINESS_YEARLY_V1_INR: "business_yearly_v1_inr",

  ADVANCED_MONTHLY_V7: "advanced_monthly_v7",
  ADVANCED_MONTHLY_V7_INR: "advanced_monthly_v7_inr",
  ADVANCED_YEARLY_V7: "advanced_yearly_v7",
  ADVANCED_YEARLY_V7_INR: "advanced_yearly_v7_inr",
  BUSINESS_MONTHLY_V7: "business_monthly_v7",
  BUSINESS_MONTHLY_V7_INR: "business_yearly_v7_inr",
  BUSINESS_YEARLY_V7: "business_yearly_v7",
  BUSINESS_YEARLY_V7_INR: "business_monthly_v7_inr",

  ADVANCED_MONTHLY_V8: "advanced_monthly_v8",
  ADVANCED_MONTHLY_V8_INR: "advanced_monthly_v8_inr",
  ADVANCED_YEARLY_V8: "advanced_yearly_v8",
  ADVANCED_YEARLY_V8_INR: "advanced_yearly_v8_inr",
  BUSINESS_MONTHLY_V8: "business_monthly_v8",
  BUSINESS_MONTHLY_V8_INR: "business_yearly_v8_inr",
  BUSINESS_YEARLY_V8: "business_yearly_v8",
  BUSINESS_YEARLY_V8_INR: "business_monthly_v8_inr",
};
export const PRICING_VERSIONS = { VERSION_1: 1, VERSION_2: 2, VERSION_3: 3, VERSION_4: 4, VERSION_5: 5, VERSION_7: 7, VERSION_8: 8  };
export const ALLOWED_KM_PLANS = {
  /* restrictions were introduced from pricing version 3 so no need 
  for v1 and v2 entries in this object */
  [PRICING_VERSIONS.VERSION_3]: [
    KM_PLAN_ID.TRIAL,
    KM_PLAN_ID.PRO_MONTHLY_V3,
    KM_PLAN_ID.PRO_YEARLY_V3,
    KM_PLAN_ID.ENTERPRISE_MONTHLY_V3,
    KM_PLAN_ID.ENTERPRISE_YEARLY_V3,
    KM_PLAN_ID.PRO_MONTHLY_V3_INR,
    KM_PLAN_ID.PRO_YEARLY_V3_INR,
    KM_PLAN_ID.ENTERPRISE_MONTHLY_V3_INR,
    KM_PLAN_ID.ENTERPRISE_YEARLY_V3_INR,
    
  ],
  [PRICING_VERSIONS.VERSION_4]: [
    KM_PLAN_ID.TRIAL,
    KM_PLAN_ID.ENTERPRISE_MONTHLY_V3,
    KM_PLAN_ID.ENTERPRISE_YEARLY_V3,
    KM_PLAN_ID.ENTERPRISE_MONTHLY_V3_INR,
    KM_PLAN_ID.ENTERPRISE_YEARLY_V3_INR
  ],
  [PRICING_VERSIONS.VERSION_5]: [
    KM_PLAN_ID.TRIAL,
    KM_PLAN_ID.SCALE_MONTHLY_V1,
    KM_PLAN_ID.SCALE_YEARLY_V1,
    KM_PLAN_ID.SCALE_MONTHLY_V1_INR,
    KM_PLAN_ID.SCALE_YEARLY_V1_INR,
    KM_PLAN_ID.BUSINESS_MONTHLY_V1,
    KM_PLAN_ID.BUSINESS_YEARLY_V1,
    KM_PLAN_ID.BUSINESS_MONTHLY_V1_INR,
    KM_PLAN_ID.BUSINESS_YEARLY_V1_INR,
  ],
  [PRICING_VERSIONS.VERSION_7]: [
    KM_PLAN_ID.TRIAL,
    KM_PLAN_ID.ADVANCED_MONTHLY_V7,
    KM_PLAN_ID.ADVANCED_MONTHLY_V7_INR,
    KM_PLAN_ID.ADVANCED_YEARLY_V7,
    KM_PLAN_ID.ADVANCED_YEARLY_V7_INR,
    KM_PLAN_ID.BUSINESS_MONTHLY_V7,
    KM_PLAN_ID.BUSINESS_MONTHLY_V7_INR,
    KM_PLAN_ID.BUSINESS_YEARLY_V7,
    KM_PLAN_ID.BUSINESS_YEARLY_V7_INR,
  ],
  [PRICING_VERSIONS.VERSION_8]: [
    KM_PLAN_ID.TRIAL,
    KM_PLAN_ID.ADVANCED_MONTHLY_V8,
    KM_PLAN_ID.ADVANCED_MONTHLY_V8_INR,
    KM_PLAN_ID.ADVANCED_YEARLY_V8,
    KM_PLAN_ID.ADVANCED_YEARLY_V8_INR,
    KM_PLAN_ID.BUSINESS_MONTHLY_V8,
    KM_PLAN_ID.BUSINESS_MONTHLY_V8_INR,
    KM_PLAN_ID.BUSINESS_YEARLY_V8,
    KM_PLAN_ID.BUSINESS_YEARLY_V8_INR,
  ]
};

export const TRIAL_DAYS_LIMIT = 30;
