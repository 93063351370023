import React from "react";
import styled, { css } from "styled-components";

const UpgradePlan = () => {
  return (
    <DeactivateWrapper>
      <h3>
        We are sorry but Helpcenter is not available at the moment.<br></br>{" "}
        Please contact support for more details
      </h3>
    </DeactivateWrapper>
  );
};
const DeactivateWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 450px;
  text-align: center;

  & h3 {
    font-weight: 700;
    font-size: 20px;
    color: #4d4d4d;
    text-transform: uppercase;
  }
`;
export default UpgradePlan;
