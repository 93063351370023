import React, { Component } from "react";
import { HelpcenterClient } from "../utils/HelpcenterClient";
import { CommonUtils } from "../utils/CommonUtils";
import { StyleUtils } from "../utils/StyleUtils";
import {
  DEFAULT_HELPCENTER_SETTINGS,
  PRODUCT_ID,
  TRIAL_DAYS_LIMIT,
  ALLOWED_KM_PLANS,
  KM_PLAN_ID
} from "../utils/Constants";

export const HelpCenterData = React.createContext({});
const theme = require('sass-extract-loader?{"plugins": ["sass-extract-js"]}!../scss/_variables.scss');

export class HelpCenterDataContext extends Component {
  constructor(props) {
    super(props);
    this.state = {
      baseUrl: "",
      appId: "",
      helpCenter: {},
      appIdFromUrl: "",
      helpCenterDeactivated: false,
      hideChatThroughURL:false
    };
  }

  fetchSettings = () => {
    HelpcenterClient.getAppSettings(this.state.appIdFromUrl)
      .then(response => {
        response &&
          response.data &&
          this.setState(
            {
              baseUrl: CommonUtils.getHostNameFromUrl(),
              appId: response.data.response.applicationId,
              helpCenter: response.data.response.helpCenter
            },
            () => {
              this.checkForMissingSettings();
              this.state.helpCenter && this.storeSettings();
              this.setHelpcenterDeactivateStatus(
                response.data.response.subscriptionDetails
              );
            }
          );
      })
      .catch(err => {
        console.log(err);
      });
  };
  checkForMissingSettings = () => {
    if (this.state.helpCenter) {
      !this.state.helpCenter.hasOwnProperty("color") &&
        (this.state.helpCenter.color = DEFAULT_HELPCENTER_SETTINGS.color);
      (!this.state.helpCenter.hasOwnProperty("heading") ||
        !this.state.helpCenter.heading) &&
        (this.state.helpCenter.heading = DEFAULT_HELPCENTER_SETTINGS.heading);
      (!this.state.helpCenter.hasOwnProperty("logo") ||
        !this.state.helpCenter.logo) &&
        (this.state.helpCenter.logo = DEFAULT_HELPCENTER_SETTINGS.logo);
      (!this.state.helpCenter.hasOwnProperty("title") ||
        !this.state.helpCenter.title) &&
        (this.state.helpCenter.title = DEFAULT_HELPCENTER_SETTINGS.title);
      !this.state.helpCenter.hasOwnProperty("showPoweredBy") &&
        (this.state.helpCenter.showPoweredBy =
          DEFAULT_HELPCENTER_SETTINGS.showPoweredBy);
      !this.state.helpCenter.hasOwnProperty("showChatWidget") &&
        (this.state.helpCenter.showChatWidget =
          DEFAULT_HELPCENTER_SETTINGS.showChatWidget);
      !this.state.helpCenter.hasOwnProperty("searchBarText") &&
        (this.state.helpCenter.searchBarText =
          DEFAULT_HELPCENTER_SETTINGS.searchBarText);
    } else {
      this.setState(
        {
          helpCenter: DEFAULT_HELPCENTER_SETTINGS
        },
        () => {
          this.storeSettings();
        }
      );
    }
  };

  storeSettings = () => {
    this.setTheme();
    this.updateFavicon();
    this.updateHelpcenterTitle();
  };

  updateFavicon = () => {
    let favicon = document.getElementById("favicon");
    favicon.href = this.state.helpCenter.favicon;
  };

  updateHelpcenterTitle = () => {
    document.title = this.state.helpCenter.title + " | Helpcenter";
  };

  setTheme = () => {
    let primaryColor = this.state.helpCenter.color,
      themeGradient = StyleUtils.getGradientColor(primaryColor),
      primaryColorBrightness = StyleUtils.getColorBrightness(primaryColor),
      textColor,
      articleTextColor;
    primaryColorBrightness > 150
      ? (textColor = "#4a4a4a")
      : (textColor = "#fff");
    primaryColorBrightness > 150
      ? (articleTextColor = "#4a4a4a")
      : (articleTextColor = primaryColor);
    const updatedTheme = Object.assign({}, theme, {
      primaryColor: primaryColor,
      gradientColor: themeGradient,
      helpcenterHeadingFontColor: textColor,
      articleTextColor: articleTextColor
    });
    this.setState({
      theme: updatedTheme
    });
  };
  setHelpcenterDeactivateStatus = subscription => {
    if (
      subscription &&
      subscription.subscriptionPlan &&
      subscription.pricingVersion
    ) {
      let deactivateHelpcenter =
        subscription.pricingVersion >= 3 &&
        (subscription.trialExpired ||
          !ALLOWED_KM_PLANS[subscription.pricingVersion].includes(subscription.subscriptionPlan));
      this.setState({
        helpCenterDeactivated: deactivateHelpcenter
      });
    }
  };
  componentDidMount = () => {
    this.setState(
      {
        appIdFromUrl: CommonUtils.getUrlParameter(
          window.location.search,
          "appId"
        ),
        hideChatThroughURL:CommonUtils.getUrlParameter(
          window.location.search,
          "hideChat"
        )=="true"
      },
      () => {
        this.fetchSettings();
      }
    );
  };
  render() {
    return (
      <HelpCenterData.Provider
        value={{
          helpCenter: this.state
        }}
      >
        {this.props.children}
      </HelpCenterData.Provider>
    );
  }
}
