import React, { Component, Fragment } from "react";
import { CommonUtils } from "../../utils/CommonUtils";
import { HelpcenterClient } from "../../utils/HelpcenterClient";
import { withRouter } from "react-router-dom";
import { ClearButton } from "../../assets/svgAssets";
import {
  ClearButtonWrapper,
  MenuWrapper,
  SeeAllButton,
  SearchBoxWrapper,
  SearchBox,
  SearchResultsWrapper,
  SearchResults,
  NoResultFoundMenuButton,
  SearchBarLoaderWrapper,
  SearchBarLoader
} from "./HeaderComponents";
import { HelpCenterData } from "../../context/HelpcenterDataContext";

let fetchFaq;

class HelpQuerySearch extends Component {
  static contextType = HelpCenterData;
  constructor(props) {
    super(props);
    this.state = {
      inputValue: "",
      searchQuery: "",
      faqList: "",
      searchedFaqList: "",
      isDropDownOpen: false,
      value: "",
      key: "",
      totalSearchResults: "",
      maxVisibleSearchedFaq: 5,
      isLoaderVisible: true,
      cursor: -1
    };
  }
  getFaqListFromServer = () => {
    var _this = this,
      timeout = 300;
    fetchFaq = setTimeout(() => {
      this.state.inputValue &&
        HelpcenterClient.searchFaq(
          this.context.helpCenter.appId,
          this.state.inputValue
        ).then(response => {
          response &&
            response.data &&
            _this.setState({
              totalSearchResults: response.data.length,
              searchedFaqList: response.data
                .filter(value => value !== null)
                .slice(0, this.state.maxVisibleSearchedFaq),
              isLoaderVisible: false
            });
        });
    }, timeout);
  };

  handleInput = e => {
    this.setState(
      {
        isDropDownOpen: e.target.value,
        inputValue: e.target.value,
        searchedFaqList: "",
        isLoaderVisible: true,
        cursor: -1
      },
      () => {
        fetchFaq && clearTimeout(fetchFaq);
        this.getFaqListFromServer();
      }
    );
  };

  handleKeyPress = event => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (
        this.state.cursor >= 0 &&
        this.state.cursor < this.state.searchedFaqList.length
      ) {
        this.openSelectedFaq(this.state.searchedFaqList[this.state.cursor]);
        this.toggleDropdown();
      } else {
        if (
          CommonUtils.getUrlParameter(window.location.search, "q") ===
            this.state.inputValue ||
          !this.state.inputValue
        ) {
          return false;
        }
        this.openSearchPage();
      }
    } else if (event.key == " " && !this.state.inputValue) {
      event.preventDefault();
    }
    if (event.key === "ArrowUp") {
      let cursorPos =
        this.state.cursor == 0
          ? this.state.searchedFaqList.length
          : --this.state.cursor;
      this.setState({
        cursor: cursorPos
      });
    } else if (event.key === "ArrowDown") {
      let cursorPos =
        this.state.cursor > this.state.searchedFaqList.length - 1
          ? 0
          : ++this.state.cursor;
      this.setState({
        cursor: cursorPos
      });
    }
  };

  openSearchPage = () => {
    let searchQuery = "q=" + this.state.inputValue;
    this.props.history.push({
      pathname: "/",
      search: searchQuery
    });
    this.toggleDropdown();
  };
  openSelectedFaq = selectedFAQ => {
    if (selectedFAQ == null) {
      this.props.history.push({
        pathname: "/"
      });
    } else {
      this.props.history.push({
        pathname: "/article/" + CommonUtils.formatFaqQuery(selectedFAQ.name)
      });
    }
  };

  clearSearchBar = () => {
    this.setState({
      inputValue: "",
      cursor: -1
    });
  };

  toggleDropdown = () => {
    this.state.inputValue &&
      this.setState({
        isDropDownOpen: !this.state.isDropDownOpen,
        searchedFaqList: null,
        cursor: -1
      });
  };
  setPageTitle = () => {
    document.title = this.context.helpCenter.helpCenter.title + " | Helpcenter";
  };
  componentDidUpdate = prevProps => {
    // // Following check will check if the user is moving back from article page or search page it will clear the searchbar and reset the page title to default
    this.props.location.key !== prevProps.location.key &&
    this.props.location.pathname === "/" &&
    !CommonUtils.getUrlParameter(this.props.location.search, "q")
      ? (this.clearSearchBar(), this.setPageTitle())
      : false;
  };
  componentDidMount = () => {
    this.setPageTitle();
  };

  render() {
    return (
      <SearchBoxWrapper
        onBlur={this.toggleDropdown}
        onFocus={this.toggleDropdown}
      >
        <SearchBox
          id={"search-box"}
          placeholder={this.context.helpCenter.helpCenter.searchBarText}
          onChange={e => {
            !this.context.helpCenter.helpCenterDeactivated &&
              this.handleInput(e);
          }}
          value={this.state.inputValue}
          onKeyDown={e => {
            !this.context.helpCenter.helpCenterDeactivated &&
              this.handleKeyPress(e);
          }}
        />
        {this.state.inputValue &&
          !this.state.searchedFaqList &&
          this.state.isLoaderVisible &&
          !this.context.helpCenter.helpCenterDeactivated && (
            <SearchBarLoaderWrapper>
              <SearchBarLoader />
            </SearchBarLoaderWrapper>
          )}
        {this.state.inputValue && (
          <ClearButtonWrapper onClick={this.clearSearchBar}>
            <ClearButton />
          </ClearButtonWrapper>
        )}
        {this.state.searchedFaqList && this.state.isDropDownOpen && (
          <SearchResultsWrapper className="animated-fast slide-animated">
            {this.state.searchedFaqList.map(
              (data, index) =>
                index <= this.state.maxVisibleSearchedFaq && (
                  <SearchResults
                    className={this.state.cursor === index ? "active" : ""}
                    key={index}
                    onMouseDown={() => {
                      this.openSelectedFaq(data);
                    }}
                  >
                    {data.name}
                  </SearchResults>
                )
            )}
            {this.state.totalSearchResults >
              this.state.maxVisibleSearchedFaq && (
              <SeeAllButton
                className={
                  this.state.cursor === this.state.searchedFaqList.length
                    ? "active"
                    : ""
                }
                onMouseDown={this.openSearchPage}
              >
                See all results
              </SeeAllButton>
            )}
            {this.state.searchedFaqList.length == 0 && (
              <NoResultFoundMenuButton>
                No results found
              </NoResultFoundMenuButton>
            )}
          </SearchResultsWrapper>
        )}
      </SearchBoxWrapper>
    );
  }
}

HelpQuerySearch.contextType = HelpCenterData;
export default Object.assign(withRouter(HelpQuerySearch), {
  contextType: undefined
});
