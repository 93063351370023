import styled, { css } from "styled-components";
import { StyleUtils } from "../../utils/StyleUtils";

const CategoriesWrapper = styled.div`
  display: flex;
  margin: 20px auto;
  max-width: 992px;
  justify-content: space-between;
  flex-wrap: wrap;
  z-index: 0;
  position: relative;
`;

const CategoryBox = styled.div`
  height: 208px;
  z-index: 0;
  width: 48.5%;
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  cursor: pointer;
  transition: all 0.3s;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  padding: 20px 30px;
  box-shadow: 1px 2px 3px 2px rgba(0, 0, 0, 0.2);
  border: none;
  border-radius: 4px;
  background-color: #fff;
  &:hover {
    transform: translateY(-3px);
  }
  &:hover .category-menu {
    opacity: 1;
  }
  ${StyleUtils.mediaQuery.tablet`
        width: 100%;
        height: 100%;
    `}
`;

const CategoryHeading = styled.span`
  font-size: 21px;
  font-weight: 500;
  color: #2a2a2a;
  max-height: 30px;
  overflow: hidden;
  ${StyleUtils.mediaQuery.tablet`
        max-height: 100%;
        padding: 10px 0;
    `}
`;
const CategoryDescription = styled.span`
  font-size: 16px;
  line-height: 1.63;
  color: #858585;
  margin-top: -10px;
  max-height: 84px;
  overflow: hidden;
  word-break: word-wrap;
  max-width: 100%;
  ${StyleUtils.mediaQuery.tablet`
        max-height: 100%;
        padding: 20px 0;
    `}
`;
const TotalFaqCount = styled.span`
  background: rgba(85, 83, 183, 0.1);
  color: ${props => props.theme.articleTextColor};
  padding: 2px 20px;
  border-radius: 15px;
  font-size: 14px;
`;

export {
  CategoriesWrapper,
  CategoryBox,
  CategoryHeading,
  CategoryDescription,
  TotalFaqCount
};
