import React, { Component, Fragment } from "react";
import Header from "./Header/Header";
import FaqList from "../views/FaqList/FaqList";
import Article from "../views/Article/Article";
import UpgradePlan from "../views/UpgradePlan/UpgradePlan";
import Homepage from "../views/Homepage/Homepage";
import { Loading } from "../views/Loading/Loading";
import { ThemeProvider } from "styled-components";
import "../scss/main.scss";
import {
  Switch,
  Redirect,
  Route,
  BrowserRouter as Router,
  withRouter
} from "react-router-dom";
import PoweredByKommunicate from "../components/PoweredByKommunicate/PoweredByKommunicate";
import { StyleUtils } from "../utils/StyleUtils";
import PageNotFound from "../views/PageNotFound/PageNotFound";
import { CommonUtils } from "../utils/CommonUtils";
import { HelpcenterClient } from "../utils/HelpcenterClient";
import {
  HelpCenterDataContext,
  HelpCenterData
} from "../context/HelpcenterDataContext";
import { ThirdPartyScripts } from "../utils/ThirdPartyScripts";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appId: ""
    };
  }
  componentDidUpdate(prevProps) {
    //following function will search if appId is present in URL if yes the APPID url will be maintained throughout the app
    this.state.appId &&
      !CommonUtils.getUrlParameter(this.props.location.search, "appId") &&
      this.onRouteChanged(this.props.location.search);
  }

  onRouteChanged(prevSearchQuery) {
    let search =
      window.location.origin +
      window.location.pathname +
      "?appId=" +
      this.state.appId;
    CommonUtils.getUrlParameter(prevSearchQuery, "q") &&
      (search += "&q=" + CommonUtils.getUrlParameter(prevSearchQuery, "q"));
    window.history.replaceState({}, "", search);
  }

  componentDidMount = () => {
    this.setState({
      appId: CommonUtils.getUrlParameter(window.location.search, "appId")
    });
  };

  render() {
    return (
      <div>
        <HelpCenterDataContext>
          <HelpCenterData.Consumer>
            {context =>
              context.helpCenter.theme && context.helpCenter.appId ? (
                <ThemeProvider theme={context.helpCenter.theme}>
                  <Fragment>
                    <ThirdPartyScripts />
                    {!location.pathname.includes("404") && <Header />}
                    {!context.helpCenter.helpCenterDeactivated ? (
                      <Switch>
                        <Route exact path="/" component={Homepage} />
                        <Route path="/category" component={FaqList} />
                        <Route path="/article" component={Article} />
                        <Route component={PageNotFound} />
                      </Switch>
                    ) : (
                      <Switch>
                        <Route
                          exact
                          path="/helpcenter-deactivated"
                          component={UpgradePlan}
                        />
                        <Redirect from="/" to="/helpcenter-deactivated" />
                        <Redirect
                          from="/category"
                          to="/helpcenter-deactivated"
                        />
                        <Redirect
                          from="/article"
                          to="/helpcenter-deactivated"
                        />
                      </Switch>
                    )}
                    {!location.pathname.includes("404") &&
                      context.helpCenter.helpCenter.showPoweredBy && (
                        <PoweredByKommunicate
                          fill={"#cacaca"}
                          textColor={"#cacaca"}
                        />
                      )}
                  </Fragment>
                </ThemeProvider>
              ) : (
                <Loading />
              )
            }
          </HelpCenterData.Consumer>
        </HelpCenterDataContext>
      </div>
    );
  }
}
export default withRouter(App);
