export const config = {
    "development": {
        "baseurl": {    
            "kommunicateAPI": "https://api-test.kommunicate.io",
        },
        "kommunicateDashboardUrl": "https://dashboard-test.kommunicate.io",
        "kommunicateWebsiteUrl": "https://test.kommunicate.io",
        "applozicWebsiteUrl": "https://test.applozic.com",
        "widgetBaseUrl": "https://widget-test.kommunicate.io"
    },
    "test": {
        "baseurl": {
            "kommunicateAPI": "https://api-test.kommunicate.io",
        },
        "kommunicateDashboardUrl": "https://dashboard-test.kommunicate.io",
        "kommunicateWebsiteUrl": "https://test.kommunicate.io",
        "applozicWebsiteUrl": "https://test.applozic.com",
        "widgetBaseUrl": "https://widget-test.kommunicate.io"
    },
    "production": {
        "baseurl": {
            "kommunicateAPI": "https://api.kommunicate.io",
        },
        "kommunicateDashboardUrl": "https://dashboard.kommunicate.io",
        "kommunicateWebsiteUrl": "https://www.kommunicate.io",
        "applozicWebsiteUrl": "https://www.applozic.com",
        "widgetBaseUrl": "https://widget.kommunicate.io"
    },
    "production-ire": {
        "baseurl": {
            "kommunicateAPI": "https://api-ire.kommunicate.io",
        },
        "kommunicateDashboardUrl": "https://dashboard-ire.kommunicate.io",
        "kommunicateWebsiteUrl": "https://www.kommunicate.io",
        "applozicWebsiteUrl": "https://www.applozic.com",
        "widgetBaseUrl": "https://widget-ire.kommunicate.io"
    }
}


export function getEnvironment() {
    const env = process.env.NODE_ENV || 'development';
    return env;
}
