import React, { Component } from 'react';
import {Container} from '../../components/Container/Container';
import {CommonUtils} from '../../utils/CommonUtils';
import {HelpcenterClient} from '../../utils/HelpcenterClient';
import { withRouter } from 'react-router-dom';
import { NoResultsFoundSvg } from '../../assets/svgAssets';
import { HelpCenterData } from '../../context/HelpcenterDataContext';
import * as CategoryListStyles from './CategoriesComponents';

class Categories extends Component {
    static contextType = HelpCenterData;

    goToCategoriesPage = (name) => {	
        this.props.history.push({	
            pathname: '/category/' + CommonUtils.formatFaqQuery(name)
        });	
    }
    
    render() {
        return (
                <Container className="animated slide-animated">
                    <CategoryListStyles.CategoriesWrapper>
                    {
                    this.props.list && this.props.list.map((category , index)=>{
                       if(category.articleCount > 0){
                        return (
                            <CategoryListStyles.CategoryBox className="animated fadeIn" key={index} faqIndex={index} onClick={()=>{this.goToCategoriesPage(category.name)}}>
                                <CategoryListStyles.CategoryHeading id={"category-heading-" + index}>{category.name}</CategoryListStyles.CategoryHeading>
                                <CategoryListStyles.CategoryDescription>{category.description || "Description goes here!"}</CategoryListStyles.CategoryDescription>
                                <CategoryListStyles.TotalFaqCount>{category.articleCount || 0} {category.articleCount > 1 ? "Articles" : "Article"}</CategoryListStyles.TotalFaqCount>
                            </CategoryListStyles.CategoryBox>
                            )
                        }
                        })
                    }
                    </CategoryListStyles.CategoriesWrapper>
                </Container>
        )
    }
}
Categories.contextType = HelpCenterData;
export default Object.assign(withRouter(Categories), { contextType: undefined });